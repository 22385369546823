import React from "react"

import Layout from "../../components/layout"
import SEO from "../../components/seo"
import AboutComponent from "../../components/AboutComponent"

const TentangKamiMobilePage = () => {
  return (
    <Layout noHeader noFooter>
      <SEO title="Talenta itu pilihan" />
      <AboutComponent />
    </Layout>
  )
}

export default TentangKamiMobilePage
